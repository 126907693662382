import { createContext, useEffect, useState } from "react";
import { formatEther, formatUnits } from "viem";
import { useAccount, usePublicClient } from "wagmi";
import {
  CANARY_DAO_ABI,
  DAO_CONTRACT_ADDRESS,
  ERC20_ABI,
  ERC20_CONTRACT_ADDRESS,
  NFT_ABI,
  NFT_CONTRACT_ADDRESS,
  LP_ABI,
  LP_CONTRACT_ADDRESS,
  LP2_CONTRACT_ADDRESS,
  REWARD_ABI,
  REWARD_CONTRACT_ADDRESS,
  CNYX_CONTRACT_ADDRESS,
  VOTE_CONTRACT_ADDRESS,
  VOTE_ABI,
  BRONZE_CONTRACT_ADDRESS,
  BRONZE_ABI,
  BURN2MINT_ABI,
  BURN2MINT_CONTRACT_ADDRESS,
  CRBPRICE_ABI,
  CRBPRICE_ADDRESS,
  CHAD_ABI,
  CHAD_ADDRESS,
  FARM_ABI,
  FARM_ADDRESS,
  XENOSFARM_ABI,
  XENOS_FARM_ADDRESS,
  XENOS2_FARM_ADDRESS,
  CEN_FARM_ADDRESS,
  FLRB2M_ABI,
  FLRB2M_ADDRESS,
} from "../lib/constants";

// initial state
const defaultValues = {
  farmBalance: 0,
  pendingRewards: 0,
  farmStatus: 0,
  xenosfarmBalance: 0,
  xenosPendingRewards: 0,
  xenosFarmStatus: 0,
  bronzeSupply: 0,
  cenFarmBalance: 0,
  cenPendingRewards: 0,
  cenFarmStatus:0,
  frbMinted: 0,
  userCap: 0,
  burnedAmount:0,
  minted:0,
  getFarmBalances: () => {},
};
// context
export const FarmContext = createContext(defaultValues);

export const FarmProvider = ({ children }) => {
  const publicClient = usePublicClient();
  const { address, isConnected } = useAccount();

  const [state, setFarmBalances] = useState({
    farmBalance: 0,
    pendingRewards: 0,
    farmStatus: 0,
    xenosfarmBalance: 0,
    xenosPendingRewards: 0,
    xenosFarmStatus: 0,
    bronzeSupply: 0,
    xenos2farmBalance: 0,
    xenos2PendingRewards: 0,
    xenos2FarmStatus: 0,
    cenFarmBalance: 0,
    cenPendingRewards: 0,
    cenFarmStatus:0,
    frbMinted: 0,
    userCap:0,
    burnedAmount:0,
    minted:0,
  });

  const getFarmBalances = async (address) => {
    //try {
      const [farmBalance, pendingRewards, farmStatus, xenosfarmBalance, xenosPendingRewards, xenosFarmStatus, bronzeSupply, xenos2farmBalance, xenos2PendingRewards, xenos2FarmStatus, cenFarmBalance, cenPendingRewards, cenFarmStatus, frbMinted, userCap, burnedAmount, minted] = await Promise.all([
        publicClient.readContract({
          abi: ERC20_ABI,
          address: "0xc0f1f30BBa7C90e5C86620390e68d268f9f447ba",
          functionName: "balanceOf",
          args: [address],
        }),
        publicClient.readContract({
          abi: FARM_ABI,
          address: FARM_ADDRESS,
          functionName: "getPendingRewards",
          args: [address],
        }),
        publicClient.readContract({
          abi: FARM_ABI,
          address: FARM_ADDRESS,
          functionName: "farming",
          args: [address],
        }),
        publicClient.readContract({
          abi: XENOSFARM_ABI,
          address: XENOS_FARM_ADDRESS,
          functionName: "farmBalance",
          args: [address],
        }),
        publicClient.readContract({
          abi: XENOSFARM_ABI,
          address: XENOS_FARM_ADDRESS,
          functionName: "getPendingRewards",
          args: [address],
        }),
        publicClient.readContract({
          abi: XENOSFARM_ABI,
          address: XENOS_FARM_ADDRESS,
          functionName: "farming",
          args: [address],
        }),
        publicClient.readContract({
          abi: BRONZE_ABI,
          address: BRONZE_CONTRACT_ADDRESS,
          functionName: "totalSupply",
          args: [],  
        }),
        publicClient.readContract({
          abi: XENOSFARM_ABI,
          address: XENOS2_FARM_ADDRESS,
          functionName: "farmBalance",
          args: [address],
        }),
        publicClient.readContract({
          abi: XENOSFARM_ABI,
          address: XENOS2_FARM_ADDRESS,
          functionName: "getPendingRewards",
          args: [address],
        }),
        publicClient.readContract({
          abi: XENOSFARM_ABI,
          address: XENOS2_FARM_ADDRESS,
          functionName: "farming",
          args: [address],
        }),
        publicClient.readContract({
          abi: ERC20_ABI,
          address: "0xb8c8ee2a3D7BD89dDB9e841b088851FB9210C5a9",
          functionName: "balanceOf",
          args: [address],
        }),
        publicClient.readContract({
          abi: FARM_ABI,
          address: CEN_FARM_ADDRESS,
          functionName: "getPendingRewards",
          args: [address],
        }),
        publicClient.readContract({
          abi: FARM_ABI,
          address: CEN_FARM_ADDRESS,
          functionName: "farming",
          args: [address],
        }),
        publicClient.readContract({
          abi: FLRB2M_ABI,
          address: FLRB2M_ADDRESS,
          functionName: "getMintedAmount",
          args: [address],
        }),
        publicClient.readContract({
          abi: FLRB2M_ABI,
          address: FLRB2M_ADDRESS,
          functionName: "getUserCap",
          args: [address],
        }),
        publicClient.readContract({
          abi: FLRB2M_ABI,
          address: FLRB2M_ADDRESS,
          functionName: "getBurnedAmount",
          args: [address],
        }),
        publicClient.readContract({
          abi: FLRB2M_ABI,
          address: FLRB2M_ADDRESS,
          functionName: "minted",
          args: [],
        }),
      ]);
      setFarmBalances({
        farmBalance: formatEther(farmBalance || "0"),
        pendingRewards: formatEther(pendingRewards || "0"),
        farmStatus: formatUnits(farmStatus,0),
        xenosfarmBalance: formatEther(xenosfarmBalance || "0"),
        xenosPendingRewards: formatEther(xenosPendingRewards || "0"),
        xenosFarmStatus: formatUnits(xenosFarmStatus,0),
        bronzeSupply: formatUnits(bronzeSupply,0),
        xenos2farmBalance: formatEther(xenos2farmBalance || "0"),
        xenos2PendingRewards: formatEther(xenos2PendingRewards || "0"),
        xenos2FarmStatus: formatUnits(xenos2FarmStatus,0),
        cenFarmBalance: formatEther(cenFarmBalance || "0"),
        cenPendingRewards: formatEther(cenPendingRewards || "0"),
        cenFarmStatus: formatUnits(cenFarmStatus,0),
        frbMinted: formatEther(frbMinted || "0"),
        userCap: formatEther(userCap || "0"),
        burnedAmount: formatEther(burnedAmount || "0"),
        minted: formatEther(minted || "0"),
      });
    //} catch (err) {}
    
  };

  useEffect(() => {
    isConnected && getFarmBalances(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isConnected]);

  return (
    <FarmContext.Provider value={{ ...state, getFarmBalances }}>
      {children}
    </FarmContext.Provider>
  );
};
