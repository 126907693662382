import canyDaoABI from "@/src/assets/canaryDao.json";
import erc20ABI from "@/src/assets/ERC20.json";
import cnyaABI from "@/src/assets/cnya.json";
import nftABI from "@/src/assets/CanaryNft.json";
import rewardsABI from "@/src/assets/canaryRewards.json";
import burn2MintABI from "@/src/assets/burn2Mint.json";
import voteABI from "@/src/assets/vote.json";
import bronzeABI from "@/src/assets/harvestBronze.json";
import crbPrice from "@/src/assets/crbPrice.json";
import chad from "@/src/assets/chad.json";
import farm from "@/src/assets/canaryFarm.json";
import xenosFarm from "@/src/assets/xenosFarm";
import flrb2m from "@/src/assets/flareBurn2Mint.json";
import trb from "@/src/assets/trb.json";

export const CANARY_DAO_ABI = canyDaoABI;
export const ERC20_ABI = cnyaABI;
export const NFT_ABI = nftABI;
export const LP_ABI = cnyaABI;
export const REWARD_ABI = rewardsABI;
export const BURN2MINT_ABI = burn2MintABI;
export const VOTE_ABI = voteABI;
export const BRONZE_ABI = bronzeABI;
export const CRBPRICE_ABI = crbPrice;
export const CHAD_ABI = chad;
export const FARM_ABI = farm;
export const XENOSFARM_ABI = xenosFarm;
export const FLRB2M_ABI = flrb2m;
export const trb_ABI = trb;

export const DAO_CONTRACT_ADDRESS =
  "0x41a323b94261b4eE1a10f2777F5ec276E012E7CC";
export const ERC20_CONTRACT_ADDRESS =
  "0x5369B08B9a5023d6178A1491eE2F44b0B7301Fc9";
export const NFT_CONTRACT_ADDRESS =
"0x17F2DEEd107A4e114eE847E1e89d900B21ACfe28";
export const LP_CONTRACT_ADDRESS =
"0x98C671753Cf45080162a3A3eb9C6A3bbf9bf714B";
export const LP2_CONTRACT_ADDRESS =
"0xc0f1f30BBa7C90e5C86620390e68d268f9f447ba";
export const REWARD_CONTRACT_ADDRESS =
"0x9CC4eBc1BF33F3bFC7107B1a0160471daDc09147";
export const CNYX_CONTRACT_ADDRESS =
"0x8d32E20d119d936998575B4AAff66B9999011D27";
export const BURN2MINT_CONTRACT_ADDRESS =
"0x90215056A7A8D43C26cb88636b089285f98058d4";
export const VOTE_CONTRACT_ADDRESS =
"0x9F7bad559DBfc168677645c1C79ef0c2634E8d48";
export const BRONZE_CONTRACT_ADDRESS =
"0xCd592cAe3F5466d6B2dbC121D384d44675CC3a62";
export const SILVER_CONTRACT_ADDRESS =
"0x41004f7C32D19Aaa21b3BCEB505DF75F50ea3751";
export const GOLD_CONTRACT_ADDRESS =
"0x2072a6E5961441bA82B0d39c5459873Eaaf6F998";
export const CRBPRICE_ADDRESS =
"0x6cBA593a19D0e6400aA84B22e459060F33e8eFda";
export const CHAD_ADDRESS =
"0xC2EA1a2A2Cf6E81420727b5163c01Ae1a413B54f";
export const FARM_ADDRESS =
"0xC4d6601dB12cBcC90774A0b3E02D6A6853D3D7bC";
export const XENOS_FARM_ADDRESS =
"0xAE290b09583b198D882A6D12332273f33B803419";
export const XENOS2_FARM_ADDRESS =
"0xeBB5bA81A894968e9109855fe6ffcc8562aE8C18";
export const CEN_FARM_ADDRESS =
"0xaF97b6e7b8E32C2B8C2f4d62ce827C495263Dafc";
export const FLRB2M_ADDRESS =
"0x5e615400Ec0254c18DAdaf3602f0E25f6B768fb3";
export const TRB_ADDRESS =
"0x302Ce8e51b000D6a5b7353a8Dd12e5F79Ff83281";